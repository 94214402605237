import * as actionTypes from './actionTypes';

const navState = {
    y: 0,
    scrollDirection: ''
}

if (typeof document !== 'undefined') {
    navState.y = document.scrollingElement.scrollHeight;
}

export const setScreenSize = () => {
    return {
        type: actionTypes.SET_SCREEN_SIZE,
        isMobile: window.innerWidth < 768,
        isTablet: window.innerWidth > 767 && window.innerWidth < 1024
    }
}

export const setOpenModal = (openModal) => {
    if (typeof window !== 'undefined') {
        openModal ? document.body.style.overflow = "hidden" : document.body.style.overflow = "visible";
    }
    return {
        type: actionTypes.SET_OPEN_MODAL,
        openModal: openModal
    }
}

export const setMenuOpen = (menuOpen) => {
    return {
        type: actionTypes.SET_MENU_OPEN,
        menuOpen: menuOpen
    }
}

export const setOverAge = (overAge) => {
    return {
        type: actionTypes.SET_OVER_AGE,
        overAge: overAge
    }
}

export const setSliceOverlay = (active) => {
    return {
        type: actionTypes.SET_SLICE_OVERLAY,
        sliceOverlayActive: active
    }
}

export const setActiveSection = (section) => {
    return {
        type: actionTypes.SET_ACTIVE_SECTION,
        activeSection: section
    }
}

export const setScrollDirection = () => {
    return {
        type: actionTypes.SET_SCROLL_DIRECTION,
        scrollDirection: scroll()
    }
}

export const scroll = () => {
    if(navState.y > window.scrollY) {
        navState.scrollDirection = "up";
    } else if(navState.y < window.scrollY) {
        navState.scrollDirection = "down";
    }
    navState.y = window.scrollY;

    return navState.scrollDirection;
}
