import * as actionTypes from '../actions/actionTypes';

const initialState = {
    openModal: false,
    menuOpen: false,
    isMobile: true,
    isTablet: false,
    overAge: false,
    sliceOverlayActive: false,
    activeSection: null,
    scrollDirection: 'down'
};


if (typeof window !== 'undefined') {
    initialState.isMobile = window.innerWidth < 768;
    initialState.isTablet = window.innerWidth > 767 && window.innerWidth < 1024;
    initialState.overAge = localStorage.getItem('overAge') ? true : false;
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_OPEN_MODAL:
            return { ...state, openModal: action.openModal };
        case actionTypes.SET_MENU_OPEN:
            return { ...state, menuOpen: action.menuOpen };
        case actionTypes.SET_OVER_AGE:
            return { ...state, overAge: action.overAge };
        case actionTypes.SET_SLICE_OVERLAY:
            return { ...state, sliceOverlayActive: action.sliceOverlayActive };
        case actionTypes.SET_ACTIVE_SECTION:
            return { ...state, activeSection: action.activeSection };
        case actionTypes.SET_SCROLL_DIRECTION:
            return { ...state, scrollDirection: action.scrollDirection };
        case actionTypes.SET_SCREEN_SIZE:
            return { ...state, isMobile: action.isMobile, isTablet: action.isTablet };
        default:
            return state;
    }
};

export default reducer;