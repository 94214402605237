import { createStore } from 'redux';
import Reducer from '../redux/reducers/reducers';

const initialState = {
    isMobile: true,
    isTablet: false,
    openModal: false,
    menuOpen: false,
    overAge: false,
    sliceOverlayActive: false,
    activeSection: null,
    scrollDirection: 'down'
}

if (typeof window !== 'undefined') {
   initialState.isMobile = window.innerWidth < 1024;
   initialState.isTablet = window.innerWidth > 767 && window.innerWidth < 1024;
   initialState.overAge = localStorage.getItem('overAge') ? true : false;
}

const store = createStore(Reducer, initialState);

export default store;